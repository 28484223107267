import { render, staticRenderFns } from "./EditoBlocks.vue?vue&type=template&id=5078016a"
import script from "./EditoBlocks.vue?vue&type=script&lang=ts"
export * from "./EditoBlocks.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CarouselProductTailoredSuggestions: require('/home/runner/work/webapp/webapp/components/organisms/carousel/CarouselProductTailoredSuggestions.vue').default,EditoHandmadeBanner: require('/home/runner/work/webapp/webapp/components/molecules/edito/EditoHandmadeBanner.vue').default,EditoAppPromoBanner: require('/home/runner/work/webapp/webapp/components/molecules/edito/EditoAppPromoBanner.vue').default,EditoProBanner: require('/home/runner/work/webapp/webapp/components/molecules/edito/EditoProBanner.vue').default,EditoBlockMain: require('/home/runner/work/webapp/webapp/components/organisms/edito/EditoBlockMain.vue').default,BaseBanner: require('/home/runner/work/webapp/webapp/components/molecules/banner/BaseBanner.vue').default,EditoBlockCallouts: require('/home/runner/work/webapp/webapp/components/organisms/edito/EditoBlockCallouts.vue').default,EditoBlockCallToAction: require('/home/runner/work/webapp/webapp/components/molecules/edito/EditoBlockCallToAction.vue').default,CarouselCard: require('/home/runner/work/webapp/webapp/components/organisms/carousel/CarouselCard.vue').default,EditoBlockSelection: require('/home/runner/work/webapp/webapp/components/organisms/edito/EditoBlockSelection.vue').default,EditoBlockTyper: require('/home/runner/work/webapp/webapp/components/atoms/edito/EditoBlockTyper.vue').default,CarouselProductLastViewed: require('/home/runner/work/webapp/webapp/components/organisms/carousel/CarouselProductLastViewed.vue').default})
